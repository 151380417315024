<template>
  <div>
    <b-modal
      id="modal-input-tindakan"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-form @submit.stop.prevent>
              <b-form-group label-cols-md="3">
                <template v-slot:label> BPJS </template>
                <b-form-select
                  :state="checkIfValid('is_bpjs')"
                  v-model="$v.is_data.is_bpjs.$model"
                  :options="option_bpjs"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols-md="3"
                description="Apabila kosong semua poli bisa menggunakan"
              >
                <template v-slot:label> Poli </template>
                <b-form-select
                  :state="checkIfValid('ms_poli_id')"
                  v-model="$v.is_data.ms_poli_id.$model"
                  :options="option_poli"
                  placeholder="-- Pilih Poli ---"
                ></b-form-select>
              </b-form-group>

              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Tindakan <span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="text"
                  :state="checkIfValid('nama_tindakan')"
                  v-model="$v.is_data.nama_tindakan.$model"
                ></b-form-input>
              </b-form-group>

              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Kode Tindakan
                </template>
                <b-form-input
                  :disabled="!is_data.is_bpjs"
                  type="text"
                  :state="checkIfValid('kd_tindakan')"
                  v-model="$v.is_data.kd_tindakan.$model"
                ></b-form-input>
              </b-form-group>

              <b-form-group label-cols-md="3" description="Khusus data dari BPJS">
                <template v-slot:label> Tarif Maksimal </template>
                <b-form-input
                  readonly
                  type="number"
                  :state="checkIfValid('max_tarif')"
                  v-model="$v.is_data.max_tarif.$model"
                ></b-form-input>
              </b-form-group>
            </b-form>
            <b-form-group label-cols-md="3">
              <template v-slot:label> Tarif <span class="text-danger">*</span> </template>
              <b-form-input
                type="text"
                :state="checkReactive('jumlah_tarif_tindakan_format')"
                v-model="$v.jumlah_tarif_tindakan_format.$model"
                :formatter="numberFormat"
              ></b-form-input>
            </b-form-group>
            <!-- <img :src="src1" /> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-2">
          <b-col cols="auto">
            <h5>Komponen Tarif</h5>
          </b-col>
          <b-col cols="auto" class="ml-auto">
            <b-button variant="primary" @click="addKomponen" size="sm">
              <CIcon name="cil-plus" />
            </b-button>
          </b-col>
        </b-row>
        <b-row v-for="(item, index) in data_komponen" :key="index" align-v="center">
          <b-col cols="12">
            <hr v-if="index > 0" />
          </b-col>
          <b-col cols="6"> Komponen <span class="text-danger">*</span> </b-col>
          <b-col cols="5" offset="1"> Tarif <span class="text-danger">*</span> </b-col>
          <b-col cols="6">
            <Multiselect
              v-model="item.obj_komp"
              :options="filteredOptions(item)"
              trackBy="ms_komponen_tarif_id"
              label="nama_komponen_tarif"
              :show-labels="false"
            />
          </b-col>
          <b-col cols="1">:</b-col>
          <b-col cols="5">
            <b-form-input
              v-model="item.harga_pool_tindakan_komponen_tarif"
              type="text"
              size="lg"
            ></b-form-input>
          </b-col>
          <b-col cols="12" style="display: flex; justify-content: end">
            <b-button pill variant="danger" @click="removeKomponen(index)" size="sm">
              Hapus
              <!-- <CIcon name="cil-trash" /> -->
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-tindakan')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["fileName", "option_poli", "option_komponen_tarif"],
  data() {
    return {
      is_data: {
        ms_poli_id: null,
        kd_tindakan: "",
        nama_tindakan: "",
        max_tarif: 0,
        jumlah_tarif_tindakan: 0,
        is_bpjs: false,
        bulk_tindakan_komponen_tarif: [],
      },
      jumlah_tarif_tindakan_format: "",
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      option_bpjs: [
        { text: "Tidak", value: false },
        { text: "Ya", value: true },
      ],
      data_komponen: [{ obj_komp: {}, harga_pool_tindakan_komponen_tarif: "0" }],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_poli_id: {},
      kd_tindakan: {
        required: requiredIf(function () {
          return this.is_data.is_bpjs
        })
      },
      nama_tindakan: { required },
      max_tarif: {},
      jumlah_tarif_tindakan: { required },
      is_bpjs: {},
    },
    jumlah_tarif_tindakan_format: { required },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    checkReactive(fieldName) {
      const field = this.$v[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    filteredOptions(item) {
      const selectedIds = this.data_komponen
        .filter((i) => i !== item)
        .map((i) => i.obj_komp.ms_komponen_tarif_id);

      return this.option_komponen_tarif.filter(
        (option) => !selectedIds.includes(option.ms_komponen_tarif_id)
      );
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      // ms_poli_id, kd_tindakan, nama_tindakan, max_tarif, jumlah_tarif_tindakan, is_bpjs , bulk_tindakan_komponen_tarif
      vm.is_data.bulk_tindakan_komponen_tarif.splice(0);
      vm.data_komponen.forEach((el) => {
        let x = {};
        (x.ms_komponen_tarif_id = el.obj_komp.ms_komponen_tarif_id),
          (x.harga_pool_tindakan_komponen_tarif = el.harga_pool_tindakan_komponen_tarif);
        vm.is_data.bulk_tindakan_komponen_tarif.push(x);
      });
      // vm.is_data.bulk_tindakan_komponen_tarif = vm.data_komponen
      vm.$axios
        .post(`/${vm.file_name}/register_bulk`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input-tindakan");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: res.data.message.toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    addKomponen() {
      let x = {
        obj_komp: {},
        tarif: "0",
      };
      this.data_komponen.push(x);
    },
    removeKomponen(ix) {
      this.data_komponen.splice(ix, 1);
    },
    numberFormat(value) {
      this.is_data.jumlah_tarif_tindakan = Number(value.replace(/\D/g, ""));
      return value == "0" ? "" : this.$numberFormat(this.is_data.jumlah_tarif_tindakan);
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        ms_poli_id: null,
        kd_tindakan: "",
        nama_tindakan: "",
        max_tarif: 0,
        jumlah_tarif_tindakan: 0,
        is_bpjs: false,
        bulk_tindakan_komponen_tarif: [],
      };
      this.data_komponen = [{ obj_komp: {}, tarif_pool_tindakan_komponen_tarif: 0 }];
      this.jumlah_tarif_tindakan_format = "";
    },
  },
  components: { Multiselect },
};
</script>
