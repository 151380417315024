<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ pageName }}</strong>
              </h5>
            </template>
            <b-row align-h="between">
              <b-col cols="6" md="6" lg="6">
                <b-button
                  
                  variant="primary"
                  v-b-modal.modal-input-tindakan
                >
                  <CIcon name="cil-plus" /> Tambah Data
                </b-button>
              </b-col>
              <b-col cols="auto">
                <b-button class="mr-2" variant="warning" pills></b-button>
                <small> <i>Silahkan dilengkapi data yang berwarna kuning</i> </small>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">
              {{ msg }}
            </b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                  :tbody-tr-class="rowClass"
                >
                  <template #cell(actions)="item">
                    <b-button
                      
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      @click="openDetail(item.item)"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-tindakan
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      :fileName="file_name"
      :option_poli="option_poli"
      :option_komponen_tarif="option_komponen_tarif"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :fileName="file_name"
      :data_edit="is_data"
      :option_poli="option_poli"
      :option_komponen_tarif="option_komponen_tarif"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
  },
  name: "ms_tindakan",
  data() {
    return {
      file_name: "ms_tindakan",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "is_bpjs",
          label: "BPJS",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? "Ya" : "Tidak";
          },
        },
        {
          key: "nama_poli",
          label: "Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-";
          },
        },
        {
          key: "kd_tindakan",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-";
          },
        },
        {
          key: "nama_tindakan",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-";
          },
        },
        {
          key: "jumlah_tarif_tindakan",
          label: "Tarif",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$numberFormat(val) : "-";
          },
        },
        {
          key: "max_tarif",
          label: "Tarif Maksimal BPJS",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$numberFormat(val) : "-";
          },
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,

      option_poli: [],
      option_komponen_tarif: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      return this.fields.map((el) => el.key);
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    role() {
      return this.$store.state.user_role;
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      try {
        vm.tableBusy = true;
        let res = await vm.$axios.post(`/${vm.file_name}/list`);
        vm.items =
          res.data.status == 200
            ? res.data.data.map((x, i) => {
                return {
                  ...x,
                  no: i + 1,
                };
              })
            : [];
        vm.totalRows = vm.items.length;

        // Poli
        let option_poli = await vm.$axios.post("/ms_poli/list");
        vm.option_poli =
          option_poli.data.status == 200
            ? option_poli.data.data.map((x) => ({
                ...x,
                value: x.ms_poli_id,
                text: x.nama_poli,
              }))
            : [];
        vm.option_poli.unshift({
          value: null,
          text: "Pilih disini untuk membatalkan atau silahkan pilih poli",
        });

        // komponen tarif
        let option_komponen_tarif = await vm.$axios.post("/ms_komponen_tarif/list");
        vm.option_komponen_tarif =
          option_komponen_tarif.data.status == 200
            ? option_komponen_tarif.data.data.map((x) => ({
                ...x,
                value: x.ms_komponen_tarif_id,
                text: x.nama_komponen_tarif,
              }))
            : [];
      } catch (err) {
        if ("message" in err) {
          this.triggerAlert({
            variant: "danger",
            msg: err.message.toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: err.toUpperCase(),
            showing: true,
          });
        }
      } finally {
        this.tableBusy = false;
      }
    },
    async openDetail(value) {
      const vm = this;
      try {
        const res = await vm.$axios(
          `/${vm.file_name}/details_by_id/${value.ms_tindakan_id}`
        );
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.is_data = res.data.data[0];
          vm.$bvModal.show("modal-edit-tindakan");
        } else {
          throw res.data;
        }
      } catch (err) {
        if ("message" in err) {
          this.triggerAlert({
            variant: "danger",
            msg: err.message.toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: err.toUpperCase(),
            showing: true,
          });
        }
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.jumlah_tarif_tindakan) return "table-warning";
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
