<template>
  <div>
    <b-modal
      id="modal-edit-tindakan"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="warning"
      header-text-variant="light"
      @hidden="tutupModal"
      @shown="checkPoolTindakan()"
    >
      <b-container v-if="is_data">
        <b-row>
          <b-col>
            <b-form @submit.stop.prevent>
              <b-form-group label-cols-md="3">
                <template v-slot:label> BPJS </template>
                <b-form-select
                  :state="checkIfValid('is_bpjs')"
                  v-model="$v.is_data.is_bpjs.$model"
                  :options="option_bpjs"
                  @change="addKey('is_bpjs')"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols-md="3"
                description="Apabila kosong semua poli bisa menggunakan"
              >
                <template v-slot:label> Poli </template>
                <b-form-select
                  :state="checkIfValid('ms_poli_id')"
                  v-model="$v.is_data.ms_poli_id.$model"
                  :options="option_poli"
                  placeholder="-- Pilih Poli ---"
                  @change="addKey('ms_poli_id')"
                ></b-form-select>
              </b-form-group>

              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Tindakan <span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="text"
                  :state="checkIfValid('nama_tindakan')"
                  v-model="$v.is_data.nama_tindakan.$model"
                  @change="addKey('nama_tindakan')"
                ></b-form-input>
              </b-form-group>

              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Kode Tindakan <span class="text-danger">*</span>
                </template>
                <b-form-input
                  type="text"
                  :disabled="!is_data.is_bpjs"
                  :state="checkIfValid('kd_tindakan')"
                  v-model="$v.is_data.kd_tindakan.$model"
                ></b-form-input>
              </b-form-group>

              <b-form-group label-cols-md="3" description="Khusus data dari BPJS">
                <template v-slot:label> Tarif Maksimal </template>
                <b-form-input
                  readonly
                  type="number"
                  :state="checkIfValid('max_tarif')"
                  v-model="$v.is_data.max_tarif.$model"
                  @change="addKey('max_tarif')"
                ></b-form-input>
              </b-form-group>
            </b-form>
            <b-form-group label-cols-md="3">
              <template v-slot:label> Tarif <span class="text-danger">*</span> </template>
              <b-form-input
                type="number"
                :state="checkIfValid('jumlah_tarif_tindakan')"
                v-model="$v.is_data.jumlah_tarif_tindakan.$model"
                @change="addKey('jumlah_tarif_tindakan')"
              ></b-form-input>
            </b-form-group>
            <!-- <img :src="src1" /> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-2">
          <b-col cols="auto">
            <h5>Komponen Tarif</h5>
          </b-col>
          <b-col cols="auto" class="ml-auto">
            <b-button variant="primary" @click="addKomponen" size="sm">
              <CIcon name="cil-plus" />
            </b-button>
          </b-col>
        </b-row>
        <span v-if="pool_tindakan">
          <b-row
            v-for="(item, index) in is_data.data_pool_tindakan_komponen_tarif"
            :key="index"
            align-v="center"
          >
            <b-col cols="12">
              <hr v-if="index > 0" />
            </b-col>
            <b-col cols="6"> Komponen <span class="text-danger">*</span> </b-col>
            <b-col cols="5" offset="1"> Tarif <span class="text-danger">*</span> </b-col>
            <b-col cols="6">
              <b-form-select
                v-model="item.ms_komponen_tarif_id"
                :options="filteredOptions(item)"
              />
            </b-col>
            <b-col cols="1">:</b-col>
            <b-col cols="5">
              <b-form-input
                v-model="item.harga_pool_tindakan_komponen_tarif"
                type="number"
                size="lg"
              ></b-form-input>
            </b-col>
            <b-col cols="12" style="display: flex; justify-content: end">
              <b-button pill variant="danger" @click="removeKomponen(index)" size="sm">
                Hapus
              </b-button>
            </b-col>
          </b-row>
        </span>
      </b-container>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-tindakan')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required,requiredIf, between } from "vuelidate/lib/validators";
export default {
  name: "modalEdit",
  props: ["fileName", "data_edit", "option_poli", "option_komponen_tarif"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
      is_edited: {},
      max: 0,
      option_bpjs: [
        { text: "Tidak", value: false },
        { text: "Ya", value: true },
      ],
      data_komponen: [{ obj_komp: {}, harga_pool_tindakan_komponen_tarif: 0 }],
      pool_tindakan: false,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    maxValue() {
      return this.max ? this.max : 0;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      this.max = this.is_data.max_tarif
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_poli_id: {},
      kd_tindakan: {
        required: requiredIf(function () {
          return this.is_data.is_bpjs
        })
      },
      nama_tindakan: { required },
      max_tarif: {},
      jumlah_tarif_tindakan: {
        required,
        between(value){
          return between(0, this.maxValue)(value)
        }
      },
      is_bpjs: {},
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    filteredOptions(item) {
      const selectedIds = this.is_data.data_pool_tindakan_komponen_tarif
        .filter((i) => i !== item)
        .map((i) => i.ms_komponen_tarif_id);

      return this.option_komponen_tarif.filter(
        (option) => !selectedIds.includes(option.ms_komponen_tarif_id)
      );
    },
    checkPoolTindakan() {
      if (this.is_data.data_pool_tindakan_komponen_tarif[0].ms_komponen_tarif_id) {
        this.pool_tindakan = true
      } else {
        this.pool_tindakan = false
      }
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        if (!("id" in this.is_edited)) {
          this.is_edited.id = this.is_data.ms_tindakan_id;
        }
        vm.is_edited.bulk_tindakan_komponen_tarif = vm.is_data.data_pool_tindakan_komponen_tarif.map(
          ({ ms_komponen_tarif_id, harga_pool_tindakan_komponen_tarif }) => ({
            ms_komponen_tarif_id,
            harga_pool_tindakan_komponen_tarif,
          })
        );
        console.log(vm.is_edited);
        vm.$axios
          .post(`/${vm.file_name}/update_bulk`, vm.is_edited)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$bvModal.hide("modal-edit-tindakan");
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH " + vm.pageName.toUpperCase(),
                showing: true,
              });
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = null;
      this.is_edited = {};
    },
    addKey(key, bulk = false) {
      if (!("id" in this.is_edited)) {
        this.is_edited.id = this.is_data.ms_tindakan_id;
      }
      this.is_edited[key] = this.is_data[key];
    },
    addKomponen() {
      let x = {
        obj_komp: {},
        tarif: 0,
      };
      this.data_komponen.push(x);
    },
    removeKomponen(ix) {
      this.is_data.data_pool_tindakan_komponen_tarif.splice(ix, 1);
    },
  },
};
</script>
